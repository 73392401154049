import React, { useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { func, node, object, shape, string } from 'prop-types';

import Field from '../../Field';
import { IconProfileCard, IconSpinner } from '../../../../components';
import { useConfiguration } from '../../../../context/configurationContext';
import { formatMoney } from '../../../../util/currency';

import css from './SectionContainer.module.css';
import { createResourceLocatorString } from '../../../../util/routes';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { subCategory } from '../../../../config/configListing';
import { getSearchCategoryParams } from '../../../CMSPage/CMSPage.duck';
import { querySearchCategory } from '../../../../ducks/hostedAssets.duck';
import { FormattedMessage } from '../../../../util/reactIntl';
import { onGetSubCategoryChilds, sortAccorrdingToHeroImage, truncateLabel } from '../../../../util/data';
import { createSlug } from '../../../../util/urlHelpers';
import NoImageIcon from '../../../../components/ResponsiveImage/NoImageIcon';

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

// This component can be used to wrap some common styles and features of Section-level components.
// E.g: const SectionHero = props => (<SectionContainer><H1>Hello World!</H1></SectionContainer>);

const SectionContainer = props => {
  const { className, rootClassName, id, as, children, appearance, options, ...otherProps } = props;
  const Tag = as || 'section';
  const classes = classNames(rootClassName || css.root, className);

  const history = useHistory();
  const config = useConfiguration();
  const intl = useIntl();
  const routes = useRouteConfiguration();
  const dispatch = useDispatch();

  const {
    searchCatRequest,
    searchCatListings,
    searchCatError,
    searchQueryParams
  } = useSelector((state) => state.hostedAssets);
  const [tabName, setTabName] = useState('');

  const { pub_subCategory, pub_type, pub_variantColor,
    selectedCatValues
  } = searchQueryParams || {};

  const pub_primaryCategory = selectedCatValues && selectedCatValues.option;

  const subCategoryChildType = pub_primaryCategory && pub_subCategory ? onGetSubCategoryChilds(pub_primaryCategory, pub_subCategory, "Type") : [];

  const subCategoryChildColor = pub_primaryCategory && pub_subCategory ? onGetSubCategoryChilds(pub_primaryCategory, pub_subCategory, "Colour") : [];

  const onTabChange = tabName => {
    const { sub, type, color } = tabName || {};
    // Extract path segment outside the state setter
    const pathSegment = history.location.pathname.split('/')[2];

    // Set the new tab name and handle side effects outside the state setter
    setTabName(prevTabName => {
      return tabName;
    });

    // Define params and fetch data
    const params = {
      pageId: pathSegment,
      subCat: sub || pub_subCategory,
    };
    const data = getSearchCategoryParams(params);

    // Dispatch action with the fetched data
    dispatch(querySearchCategory({
      ...data,
      pub_type: type
      , pub_variantColor: [color]
    }));
  };

  const goToSearchPage = (params) => {
    return history.push(createResourceLocatorString('SearchPage', routes, {}, { ...params }))
  };


  return (
    <Tag className={
      id === "hero-section" ? css.heroSection :
        id === "our-collection" ? css.collectionSection :
          id === "rita-story" ? css.storySection :
            id === "producer-section" ? css.producerSection :
              id === "consumer-section" ? css.consumerSection :
                id === "footer" ? css.footerSection :
                  id === "pepi-hero" ? css.pepiHeroSection :
                    id === "item-section" ? css.itemFlexSection :
                      id === "empower-section" ? css.empowerSection :
                        classes}
      id={id}
      {...otherProps}>
      {appearance?.fieldType === 'customAppearance' ? (
        <Field
          data={{ alt: `Background image for ${id}`, ...appearance }}
          className={className}
          options={options}
        />
      ) : null}

      <div className={css.sectionContent}>{children}
        {id == "follow-rita" ?
          <div className={css.followSection}>
            <div className={css.followWrapper}>
              <div className={css.socialSection}>
                <div className={css.socialHeading}>Follow Rita Edited</div>
                <div className={css.socialImages}>
                  <span><IconProfileCard type="instagram" /></span>
                  <span><IconProfileCard type="facebook" /></span>
                  <span><IconProfileCard type="pintrest" /></span>
                </div>
              </div>
              <div className={css.emailSection}>
                <div className={css.emailHeading}>You’ve Got Mail</div>
                <div className={css.emailSubHeading}>Subscribe to our mailing list</div>
                <div className={css.emailInput}>
                  <div>
                    <input type="email" id="email" name="email" placeholder='EMAIL ADDRESS' />
                  </div>
                  <button>SUBSCRIBE</button>
                </div>
              </div>
            </div>
          </div>
          : null}

        {id == 'item-section' ?
          <div className={css.itemSection}>
            <div className={css.topButtonWrapper}>
              <div className={css.listingButtons}>
                <button className={css.activeButton}>Recently Posted</button>
                {subCategory.filter((e) => e.parent === pub_primaryCategory).map((sub) => {
                  return (
                    <button className={pub_subCategory === sub.option ? css.activeButton : css.inactiveButton}
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        // Ensure `history` is available and then navigate
                        if (history) {
                          onTabChange({ sub: sub.option, type: null, color: null })
                        }
                      }}
                    >{truncateLabel(sub.label)}</button>
                  )
                })}
                {

                }
                {subCategoryChildType?.length > 0 && <div className={css.listingButtons}>
                  <select className={css.inactiveButton} onChange={event => {

                    event.preventDefault(); // Prevent the default link behavior

                    // Ensure `history` is available and then navigate
                    if (history) {
                      onTabChange({ type: event.target.value })
                    }
                  }}>
                    <option value={''}>Type</option>
                    {subCategoryChildType.map((type) => {
                      return (
                        <option className={pub_type === type.option ? css.activeButton : css.inactiveButton} value={type.option}>{type.label}</option>
                      )
                    })}

                  </select>
                </div>}
                {subCategoryChildColor?.length > 0 && <div className={css.listingButtons}>
                  <select className={css.inactiveButton} onChange={event => {

                    event.preventDefault(); // Prevent the default link behavior

                    // Ensure `history` is available and then navigate
                    if (history) {
                      onTabChange({ color: event.target.value })
                    }
                  }}>
                    <option className={css.inactiveButton} value={''}>Color</option>
                    {subCategoryChildColor.map((type) => {
                      return (
                        <option className={pub_variantColor.includes(type.option) ? css.activeButton : css.inactiveButton} value={type.option}>{type.label}</option>
                      )
                    })}

                  </select>
                </div>}
              </div>
              <div className={css.seeMoreButton}
                onClick={(e) => {
                  e.preventDefault();
                  return pub_primaryCategory && goToSearchPage({ pub_primaryCategory: pub_primaryCategory });
                }}>
                see more <span>{pub_primaryCategory}</span> Products
              </div>
            </div>
            {searchCatRequest ? <IconSpinner />
              : searchCatListings && searchCatListings.length ? <div className={css.itemList}>
                {searchCatListings.map((item, index) => {
                  const { attributes, images, author, id } = item;
                  const { title, price } = attributes || {};
                  const { formattedPrice } = (price && config?.currency) ? priceData(price, config.currency, intl) : {};
                  const { storeName = "" } = (author?.attributes?.profile?.publicData || {});
                  const heroImage = images && images.length > 0 && sortAccorrdingToHeroImage(item);

                  const imageUrl = heroImage && heroImage.length && heroImage[0]?.attributes?.variants["listing-card"] && heroImage[0].attributes.variants["listing-card"].url ? heroImage[0].attributes.variants["listing-card"].url : "";
                  const createListingTitle = title ? createSlug(title) : "homepage";
                  return (<div key={index} className={css.item} onClick={(e) => {
                    e.preventDefault();
                    return history.push(createResourceLocatorString('ListingPage', routes, { id: id?.uuid || "0000", slug: createListingTitle }, {}));
                  }}>
                    {imageUrl ? <img src={imageUrl} alt={"no image"} /> : <div className={css.noImageWrapper}>
                      <IconProfileCard type="no_image"/>
                      <div className={css.noImageText}>No Image</div>
                    </div>}
                    <p className={css.itemPrice}>{formattedPrice}</p>
                    <div className={css.itemName}>{title}</div>
                    <div className={css.storeName}>{storeName}</div>
                  </div>
                  )
                })}
              </div>
                : <div key={"index no "} className={css.itemListNoData} >
                  {/* No Data Found against this {pub_primaryCategory} category. */}
                  There are no listings for this Collection yet.
                </div>
            }

            <button className={css.seeAllLisitng}
              onClick={(e) => {
                e.preventDefault();
                return pub_primaryCategory && goToSearchPage({ pub_primaryCategory: pub_primaryCategory });
              }} >
              <FormattedMessage id="SectionContainer.seeAllListings" />
            </button>
          </div>
          : null}
      </div>
    </Tag>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionContainer.defaultProps = {
  rootClassName: null,
  className: null,
  as: 'section',
  children: null,
  appearance: null,
};

SectionContainer.propTypes = {
  rootClassName: string,
  className: string,
  as: string,
  children: node,
  appearance: object,
  options: propTypeOption,
};

export default SectionContainer;

import React, { useEffect } from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { IconProfileCard, LinkedLogo } from '../../../../components';

import Field from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';
import footerlogo from '../../../../assets/images/footerlogo.png'
import { Icon } from '../../../../components/IconAdd/IconAdd.example';

// The number of columns (numberOfColumns) affects styling

const GRID_CONFIG = [
  { contentCss: css.contentCol1, gridCss: css.gridCol1 },
  { contentCss: css.contentCol2, gridCss: css.gridCol2 },
  { contentCss: css.contentCol3, gridCss: css.gridCol3 },
  { contentCss: css.contentCol4, gridCss: css.gridCol4 },
];
const MAX_MOBILE_SCREEN_WIDTH = 1024;

const getIndex = numberOfColumns => numberOfColumns - 1;

const getContentCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.contentCss : GRID_CONFIG[0].contentCss;
};

const getGridCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.gridCss : GRID_CONFIG[0].gridCss;
};

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const {
    sectionId,
    className,
    rootClassName,
    numberOfColumns,
    socialMediaLinks,
    slogan,
    appearance,
    copyright,
    blocks,
    options,
    linkLogoToExternalSite,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const linksWithBlockId = socialMediaLinks?.map(sml => {
    return {
      ...sml,
      blockId: sml.link.platform,
    };
  });

  const showSocialMediaLinks = socialMediaLinks?.length > 0;
  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;
  const logoLayout = isMobileLayout ? 'mobile' : 'desktop';

  // use block builder instead of mapping blocks manually

  const LoadScript = () => {
    typeof window != "undefined" && window.fd && window.fd('form', {
      formId: '66a03ee9fc89cda0eb4a0d40',
      containerEl: '#fd-form-66a03ee9fc89cda0eb4a0d40'
    });
  };

  useEffect(() => {
    LoadScript();
  }, []);


  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={className || css.root}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {/* <div className={css.footer}>
        <div className={classNames(css.content, getContentCss(numberOfColumns))}>
          <div>
            <LinkedLogo
              rootClassName={css.logoLink}
              logoClassName={css.logoWrapper}
              logoImageClassName={css.logoImage}
              linkToExternalSite={linkLogoToExternalSite}
              layout={logoLayout}
            />
          </div>
          <div className={css.sloganMobile}>
            <Field data={slogan} className={css.slogan} />
          </div>
          <div className={css.detailsInfo}>
            <div className={css.sloganDesktop}>
              <Field data={slogan} className={css.slogan} />
            </div>
            {showSocialMediaLinks ? (
              <div className={css.icons}>
                <BlockBuilder blocks={linksWithBlockId} sectionId={sectionId} options={options} />
              </div>
            ) : null}
            <Field data={copyright} className={css.copyright} />
          </div>
          <div className={classNames(css.grid, getGridCss(numberOfColumns))}>
            <BlockBuilder blocks={blocks} sectionId={sectionId} options={options} />
          </div>
        </div>
      </div> */}
      <div className={css.footerSection}>
        <div>
          <div className={css.footerLogo}>
            <img src={footerlogo} alt='footerLogo' />
          </div>
          <div className={css.socialLinks}>
            <a href='https://www.instagram.com/rita.edited/' target='blank'>
              <IconProfileCard type="insta" />
            </a>
            <a href='https://www.facebook.com/profile.php?id=61553058894046' target='blank'>
              <IconProfileCard type="facebookfooter" />
            </a>
            <a href='https://nz.pinterest.com/Ritaedited/' target='blank'>
              <IconProfileCard type="pintrest" />
            </a>
          </div>
        </div>
        <div >
          <ul>
            <li className={css.listHeading}>ABOUT</li>
            <li><a href='/terms-of-service'>Terms of Use</a></li>
            <li><a href='/privacy-policy'>Privacy Policy</a></li>
            <li><a href='/p/contact-us'>Contact Us</a></li>
          </ul>
        </div>
        <div>
          <ul>
            <li className={css.listHeading}>HELPFUL LINKS</li>
            <li><a href='/p/default-landing-page'>Apply to Sell</a></li>
            <li><a href='/p/faq'>Faqs</a></li>
          </ul>
        </div>
        <div>
          <ul>
            <li className={css.emailHeading}>You’ve Got Mail</li>
          </ul>
          <div className={css.footerEmail} id="fd-form-66a03ee9fc89cda0eb4a0d40"></div>
        </div>
        {/* <div>
          <ul>
          
            <li className={css.listHeading}>You’ve Got Mail</li>
            <li className={css.emailInput}>
              <input type="email" id="email" name="email" placeholder='EMAIL ADDRESS' />
              <div><IconProfileCard type="mail"/></div>
            </li>
           
            <li className={css.socialSection}>
              <span><IconProfileCard type="insta" /></span>
              <span><IconProfileCard type="facebookfooter" /></span>
            </li>
            <li className={css.copyrightText}>Copyright © Rita Edited 2023. All rights reserved.</li>
          </ul>
        </div> */}
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionFooter;

import React, { useState } from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockDefault.module.css';
import TopbarSearchForm from '../../../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';
import { IconProfileCard } from '../../../../components';
import storeImage from '../../../../assets/images/storeImg.png';
import { useHistory } from 'react-router-dom';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);
  const [search, setSearch] = useState("");
  const history = useHistory();

  return (
    <BlockContainer id={blockId} className={
      blockId === "collection-image" ? css.imageSection :
        classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />
        {/* {blockId === 'hero-right-block' && (
          <div className={css.categoryStore}>
          <div className={css.shopWrapper}>
            <div className={css.shopImg}>
              <img src={storeImage} />
            </div>
            <a className={css.shopName}>
              Shop Name
            </a>
          </div>
          </div>
        )
        } */}
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={title} options={options} />
          <Field data={text} options={options} />


          {blockId === 'left-block' && (
            <div className={css.searchWrapper}>
              {/* <TopbarSearchForm
          onSubmit={this.handleSubmit}
          initialValues={initialSearchFormValues}
          isMobile
          appConfig={config}
        /> */}
              <input
                type="search"
                value={search}
                placeholder="Search by keyword or store name..."
                onChange={(e)=>{
                  const value = e.target.value;
                  setSearch(value);
                }}
              />
              <IconProfileCard type="search-2" />
              <button className={css.shopButton} type='button' onClick={(e)=> {
                e.preventDefault();
                return history.push(`/s?keywords=${search}`)
              } }>Shop Now</button>
            </div>
          )}

          <Field data={callToAction} className={ctaButtonClass} options={options} />
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
